<!--
 * @Description: 多选字典
 * @Author: zhang zhen
 * @Date: 2023-04-17 21:07:39
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-25 10:46:14
 * @FilePath: /page-sass/src/views/homePage/modules/JMultiSelectTag.vue
-->
<template>
  <div>
    <a-select
      style="width: 344px; max-width: 344px"
      size="large"
      :value="arrayValue"
      @change="onChange"
      :disabled="disabled"
      mode="multiple"
      :placeholder="placeholder"
      :getPopupContainer="getParentContainer"
      optionFilterProp="children"
      :filterOption="filterOption"
      :maxTagCount="maxTagCount"
      allowClear
      :showArrow="true"
    >
      <!-- <img src="/Polygon.png" alt="" class="icon-arrow-tag" slot="suffixIcon"> -->
      <a-select-option v-for="(item, index) in dictOptions" :key="index" :value="item.dictKey">
        <a-checkbox :checked="arrayValue.includes(item.dictKey)">
          <span style="display: inline-block; width: max-content" :title="item.dictValue">
            {{ item.dictValue }}
          </span>
        </a-checkbox>
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { getAction } from '@/api/manage'
export default {
  name: 'MultiSelectTag',
  props: {
    dictCode: String,
    placeholder: String,
    disabled: Boolean,
    value: String,
    options: Array,
    spliter: {
      type: String,
      required: false,
      default: ',',
    },
    maxTagCount: {
      type: Number,
      default: 3,
    },
    popContainer: {
      type: String,
      default: '',
      required: false,
    },
    optionType: {
      type: String,
      default: 'select',
    },
  },
  data() {
    return {
      dictOptions: [],
      tagType: '',
      arrayValue: !this.value ? [] : this.value.split(this.spliter),
    }
  },
  watch: {
    options: function (val) {
      this.setCurrentDictOptions(val)
    },
    dictCode: {
      immediate: true,
      handler() {
        this.initDictData()
      },
    },
    value(val) {
      if (!val) {
        this.arrayValue = []
      } else {
        this.arrayValue = this.value.split(this.spliter)
      }
    },
  },
  methods: {
    initDictData() {
      getAction('/basic/dict/queryByDictNo', { dictNo: this.dictCode }).then((res) => {
        if (res.success) {
          this.dictOptions = res.data
        }
      })
    },
    onChange(selectedValue) {
      //   this.$forceUpdate()
      this.$emit('change', selectedValue.join(this.spliter))
    },
    setCurrentDictOptions(dictOptions) {
      this.dictOptions = dictOptions
    },
    getCurrentDictOptions() {
      return this.dictOptions
    },
    getParentContainer(node) {
      if (!this.popContainer) {
        return node.parentNode
      } else {
        return document.querySelector(this.popContainer)
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
}
</script>
<style lang="less">
.ant-select-selection--multiple .ant-select-selection__choice {
  background-color: #f9f9f9 !important;

  .ant-checkbox-wrapper-checked {
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 12px !important;
  }

  .ant-checkbox-checked {
    display: none;
  }
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon {
  display: none;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}

.ant-select-lg .ant-select-selection__rendered > ul > li {
  height: 30px;
  line-height: 28px;
}

.ant-select-disabled {
  .ant-select-selection__choice .ant-checkbox-wrapper-checked {
    color: rgba(0, 0, 0, 0.33) !important;
  }
}

.ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 24px;
  line-height: 20px;
  margin-top: 7px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__choice__content {
  height: 22px;
}
.ant-select-lg .ant-select-selection__choice__remove {
  margin-top: 1px;
}
.ant-select-lg .ant-select-selection--multiple {
  padding-bottom: 0;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__render {
  margin-top: 8px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__choice {
  padding-right: 10px;
  padding-left: 0;
}
.ant-select-lg .ant-select-selection__choice__disabled .ant-select-selection__choice__content {
  color: #000000d9;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
}
.ant-select-lg .ant-select-selection__placeholder {
  font-size: 14px;
}

.ant-select-lg.ant-select-focused {
  .ant-select-arrow {
    color: #ff6026;
  }
}
</style>
